<template>
    <footer class="app-footer">
        <div class="container">
            <ul class="footer-links">
                <li>
                    <div class="trustarc-placeholder" v-html="trustArcBlock"></div>
                </li>
                <li v-for="item in navigationItems">
                    <NuxtLink :to="item.url">{{ item.title }}</NuxtLink>
                </li>
            </ul>
            <RichText :value="openText" />
            <div class="footer-copyright">
                Copyright {{ year }} © {{ copyrightText }}
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import type { NavigationItem } from '~/types/NavigationItem';

const trustArcBlock = ref('')
const navigationItems = ref<NavigationItem[]>([])
const siteStore = useSiteStore()
const year = new Date().getFullYear()
const copyrightText = ref(siteStore.site.elements.legalText.value)
const openText = ref(siteStore.site.elements.openTextArea.value)
navigationItems.value = siteStore.getFooterNavigationItems()
trustArcBlock.value = siteStore.site.elements.trustarcCodeBlock.value

</script>

<style lang="scss" scoped></style>
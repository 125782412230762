<template>
    <header class="sticky-top">
        <div class="app-header">
            <div class="container-fluid">
                <div class="navbar navbar-expand-lg">
                    <a href="/" class="navbar-brand">
                        <NuxtImg provider="kontentAiProvider" :src="logo" alt="GNPC Logo" class="logo" />
                    </a>
                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <img src="@/assets/img/icon-hamburger.svg" class="open" />
                        <img src="@/assets/img/icon-close.svg" class="close" />
                    </button>
                    <div class="collapse navbar-collapse" id="navbarToggler">
                        <ul class="navbar-nav">
                            <li class="nav-item" v-for="item in navigationItems">
                                <NuxtLink :to="item.url" class="nav-link" :class="{ 'active': isActivePage(item.url) }">
                                    {{ item.title }}</NuxtLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import type { NavigationItem } from '~/types/NavigationItem';

const logo = ref('');
const navigationItems = ref<NavigationItem[]>([])
const siteStore = useSiteStore()
if (siteStore.site.elements.websiteLogo.value.length > 0) {
    logo.value = siteStore?.site?.elements?.websiteLogo?.value[0]?.url;
}
navigationItems.value = siteStore.getHeaderNavigationItems()

const route = useRoute();

if (process.client) {
    const { $bootstrap } = useNuxtApp()
    const bsCollapse = new $bootstrap.Collapse('#navbarToggler', {
        toggle: false
    })

    watch(() => route.fullPath, () => {
        nextTick(() => {
            bsCollapse.hide()
        });
    });
}


function isActivePage(linkUrl: string): boolean {
    if (route.path.includes('/cohorts/')) {
        return linkUrl.includes('cohort')
    }
    return linkUrl === route.path
}

</script>

<style lang="scss" scoped></style>